import React, { useEffect, useState } from "react";
import MainLayout from "../../components/Layouts/MainLayout";
import "./retailer.css";
import { CircleChevronLeft, FileText, Search } from "lucide-react";
import { Link } from "react-router-dom";
import SubRetailserTable from "../../components/Tables/SubRetailserTable";
import Pagination from "../../components/Pagination";
import AddSubRetailerModal from "../../components/Modals/ManageRetailer/AddSubRetailerModal";
import AddWarrningModal from "../../components/Modals/ManageRetailer/AddWarrningModal";
import { useAppContext } from "../../context/AppContext";
import DeductWarrningModal from "../../components/Modals/ManageRetailer/DeductWarrningModal";
import { getUser } from "../../services/auth";
import { subRetailerMembersList } from "../../services/subRetailerServices";
import SuccessTxs from "../../components/Modals/TransferFunds/SuccessTxs";
import PendingTxn from "../../components/Modals/TransferFunds/PendingTxn";
import DeleteModal from "../../components/Modals/ManageRetailer/DeleteModal";
import SuccessDetailsModal from "../../components/Modals/ManageRetailer/SuccessDetailsModal";
import { useQuery } from "react-query";
import { getUserProfile } from "../../services/userServices";

const ManageSubRetailserScreen = () => {
  const { modal, setModal, retailRefresh, setRetailRefresh } = useAppContext();

  const [subRetailers, setSubRetailers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [retailerDetails, setRetailerDetails] = useState({});

  // console.log("subRetailers",subRetailers[0]);

  useEffect(() => {
    const handleRetailerList = async () => {
      try {
        const user = await getUser();
        const data = await subRetailerMembersList(
          user,
          currentPage,
          limit,
          search
        );
        if (data.status) {
          setRetailRefresh(false);
          setSubRetailers(data.data.records);
          setTotalPages(data.data.lastPage);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleRetailerList();
  }, [currentPage, limit, search, retailRefresh]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const { data: userProfile, isLoading } = useQuery({
    queryKey: ["userProfile"],
    queryFn: async () => {
      const user = await getUser();
      const profile = await getUserProfile(user);
      return profile;
    },
  });

  // console.log(userProfile);

  return (
    <>
      {modal === "add-retailer" && (
        <AddSubRetailerModal setRetailerDetails={setRetailerDetails} />
      )}
      {modal === "success-details" && (
        <SuccessDetailsModal retailerDetails={retailerDetails} />
      )}
      {modal === "add-warrning" && <AddWarrningModal setModal={setModal} />}
      {modal === "deduct-warrning" && (
        <DeductWarrningModal setModal={setModal} />
      )}
      {modal === "success" && <SuccessTxs message="Added Successfully" />}
      {modal === "delete" && <DeleteModal message="Deleted Successfully" />}
      {modal === "pending" && <PendingTxn />}
      <MainLayout>
        <div className="main-sub-retailer-container">
          <div>
            <Link to="/dashboard" className="back-btn">
              <CircleChevronLeft />
              Manage Sub Retailer
            </Link>
          </div>

          <div className="sub-container">
            <div className="search-container">
              <Search className="search" />
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="search"
                id="search"
                placeholder="Search Name/Mobile No./ Email ID"
              />
            </div>
            {!isLoading && userProfile?.data?.role === "RETAILER" ? (
              <div className="btn-container">
                <button onClick={() => setModal("add-retailer")}>
                  Add Sub Retailer
                </button>
              </div>
            ) : null}
          </div>

          {subRetailers.length > 0 ? (
            <>
              <div>
                <SubRetailserTable
                  userProfile={userProfile}
                  subRetailers={subRetailers}
                  currentPage={currentPage}
                  limit={limit}
                />
              </div>
              <div className="flex justify-center items-center">
                {totalPages > 1 && (
                  <div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center items-center min-h-64">
              <div>
                <FileText />
              </div>
              <p className="mt-1">No Transactions Found.</p>
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default ManageSubRetailserScreen;
