import React, { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { transferAmount } from "../../../services/dashboardServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";
import PendingTxn from "./PendingTxn";
import SuccessTxs from "./SuccessTxs";
import { getUser } from "../../../services/auth";

const FundTransferModal = ({ searchedUser, setTransferModal }) => {
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");

  const { setModal, setFundRefresh } = useAppContext();

  const handleFundTransfer = async () => {
    try {
      if (amount <= 0) {
        toast.error("Please enter an amount");
        return;
      }
      setStatus("Pending");
      const user = await getUser();
      const data = await transferAmount(user, searchedUser.id, Number(amount));
      if (data.status) {
        toast.success(data.message);
        setStatus("Success");
        //Show Success modal for 2Sec
        setTimeout(() => {
          setTransferModal("");
          setModal("");
          setStatus("");
          setFundRefresh(true);
        }, 2000);
      } else {
        setStatus("");
        toast.error(data.message);
      }
    } catch (error) {
      setStatus("");
      console.log(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-80">
        {status === "Pending" && <PendingTxn />}
        {status === "Success" && <SuccessTxs message="Transfer Success" />}
        <div className="bg-white">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Transfer Fund</div>
            <div
              onClick={() => setTransferModal("")}
              className="modal-header-body"
            >
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="p-4">
            <div className="">
              <h1>Amount</h1>
              <input
                placeholder="Enter amount"
                type="number"
                title="amount"
                name="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="h-10 border border-custom-border/30 rounded-xl w-full px-2 mt-1 outline-none"
              />
            </div>
            <div className="mt-16">
              <button
                onClick={handleFundTransfer}
                type="button"
                className="w-full bg-bg-color hover:bg-bg-color/80 h-10 rounded-xl"
              >
                Transfer Now
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default FundTransferModal;
