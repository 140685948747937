import React, { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import AddAmountModal from "../Modals/ManageRetailer/AddAmountModal";
import DeductAmountModal from "../Modals/ManageRetailer/DeductAmountModal";
import DeleteWarrningModal from "../Modals/ManageRetailer/DeleteWarrningModal";
import TransactionsModal from "../Modals/ManageRetailer/TransactionsModal";
import EditSubRetailerModal from "../Modals/ManageRetailer/EditSubRetailerModal";

const SubRetailserTable = ({
  userProfile,
  subRetailers,
  currentPage,
  limit,
}) => {
  const { modal, setModal } = useAppContext();
  const [selectedMember, setSelectedMember] = useState({});

  // console.log(subRetailers);

  return (
    <>
      {modal === "edit-retailer" && (
        <EditSubRetailerModal selectedMember={selectedMember} />
      )}
      {modal === "txns" && (
        <TransactionsModal
          setModal={setModal}
          selectedMember={selectedMember}
        />
      )}
      {modal === "delete-warrning" && (
        <DeleteWarrningModal selectedMember={selectedMember} />
      )}
      {modal === "deduct-amount" && (
        <DeductAmountModal selectedMember={selectedMember} />
      )}
      {modal === "add-amount" && (
        <AddAmountModal selectedMember={selectedMember} />
      )}
      <div className="main-table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email ID</th>
              <th>Mobile Number</th>
              <th>Country</th>
              {/* <th>KYC Status</th>
              <th>Currency</th> */}
              <th>Referral Code</th>
              <th>Amount</th>
              {userProfile?.data?.role === "RETAILER" ? <th>Action</th> : null}
            </tr>
          </thead>
          <tbody>
            {subRetailers?.map((row, id) => (
              <tr key={id}>
                <td>{id + 1 + (currentPage - 1) * limit}.</td>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.mobile}</td>
                <td>{row.country.name ?? "-"}</td>
                <td>{row.referralCode}</td>
                <td className="add-remove-retailer">
                  <p
                    style={{
                      color: row.nativeBalance === 0 ? "red" : "",
                      fontSize: "14px",
                    }}
                  >
                    {row.nativeBalance.toFixed(2)}
                  </p>
                  {userProfile?.data?.role === "RETAILER" ? (
                    <div className="add-remove-btn">
                      <button
                        title="Add Funds"
                        onClick={() => {
                          setModal("add-warrning");
                          setSelectedMember(row);
                        }}
                        className="bg-bg-color p-1 rounded-md"
                      >
                        <img
                          src="/images/plus.png"
                          alt="Add"
                          loading="lazy"
                          className="w-5 h-5"
                        />
                      </button>
                      <button
                        title="Deduct Funds"
                        onClick={() => {
                          setModal("deduct-warrning");
                          setSelectedMember(row);
                        }}
                        disabled={row.nativeBalance === 0}
                        className={`p-1 rounded-md ${
                          row.nativeBalance === 0
                            ? "bg-gray-500 opacity-50 cursor-not-allowed"
                            : "bg-red-500 cursor-pointer"
                        }`}
                      >
                        <img
                          src="/images/minus.png"
                          alt="Remove"
                          loading="lazy"
                          className="w-5 h-5"
                        />
                      </button>
                    </div>
                  ) : null}
                </td>
                {userProfile?.data?.role === "RETAILER" ? (
                  <td>
                    <button
                      onClick={() => {
                        setModal("edit-retailer");
                        setSelectedMember(row);
                      }}
                      title="Edit"
                      className="action-btn edit"
                    >
                      <img
                        src="/images/edit_note.png"
                        alt="Edit"
                        loading="lazy"
                      />
                    </button>
                    <button
                      onClick={() => {
                        setModal("delete-warrning");
                        setSelectedMember(row);
                      }}
                      title="Delete"
                      className="action-btn delete"
                    >
                      <img src="/images/delete.png" alt="Edit" loading="lazy" />
                    </button>
                    <button
                      onClick={() => {
                        setModal("txns");
                        setSelectedMember(row);
                      }}
                      title="Transactions"
                      className="action-btn download"
                    >
                      <img src="/images/clock.png" alt="Edit" loading="lazy" />
                    </button>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubRetailserTable;
