import React, { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { Copy, MinusCircle, PlusCircle, X } from "lucide-react";
import "./modalRetailer.css";
import { getUser } from "../../../services/auth";
import { createMember } from "../../../services/subRetailerServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";
import ClipboardCopy from "../../../utils/copyToClipboard";
import { useQuery } from "react-query";
import { getCountryList } from "../../../services/userServices";

const AddSubRetailerModal = ({ setRetailerDetails }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    balance: "",
    address: "",
  });
  const [show, setShow] = useState(false);
  const { setModal, setRetailRefresh } = useAppContext();

  const { data: countryList, isLoading } = useQuery({
    queryKey: ["countryList"],
    queryFn: async () => {
      const user = await getUser();
      const country = await getCountryList(user);
      return country.data;
    },
  });

  // console.log(countryList);

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setModal("pending");
      const user = await getUser();
      const data = await createMember(user, formData);
      if (data.status) {
        setRetailerDetails(data.data);
        toast.success(data.message);
        setRetailRefresh(true);
        setModal("success-details");
      } else {
        setModal("add-retailer");
        toast.error(data.message);
      }
    } catch (error) {
      setModal("");
      toast.error("Someting went wrong!");
      console.log(error);
    }
  };

  const isFormValid =
    formData.name && formData.email && formData.mobile && formData.password;

  return (
    <>
      <ModalLayout maxWidth="max-w-2xl">
        <div className="bg-white">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Add Sub Retailer</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className=" p-4 w-full mt-2">
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              <div className="space-y-4 text-sm">
                <div className="flex gap-2 items-center">
                  <h1 className="flex-1 text-sm">Retailer Name</h1>
                  <input
                    type="name"
                    placeholder="Enter Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="h-8 border border-custom-border/30 rounded-lg px-1.5"
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <h1 className="flex-1 text-sm">Email ID</h1>
                  <input
                    type="email"
                    placeholder="Enter Email ID"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="h-8 border border-custom-border/30 rounded-lg px-1.5"
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <h1 className="flex-1 text-sm">Mobile Number</h1>
                  <input
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="h-8 border border-custom-border/30 rounded-lg px-1.5"
                  />
                </div>
                <div className=" flex flex-col justify-center items-center gap-2 pt-2">
                  <label style={{ textAlign: "left" }}>Balance</label>
                  <div className="border border-custom-border/30 w-full p-2 py-4 rounded-xl flex flex-col justify-center items-center">
                    <h1>00.00</h1>
                    <div className=" flex gap-4 mt-4">
                      <button
                        title="Add"
                        onClick={() => setShow(!show)}
                        className="bg-bg-color p-1.5 rounded-md"
                      >
                        <PlusCircle className="w-5 h-5" />
                      </button>
                      <button
                        title="Deduct"
                        className="bg-gray-400 opacity-30 cursor-not-allowed p-1.5 rounded-md"
                      >
                        <MinusCircle className="w-5 h-5" />
                      </button>
                    </div>
                    {show && (
                      <div className="w-full mt-4">
                        <input
                          placeholder="Enter Amount"
                          name="balance"
                          value={formData.balance}
                          onChange={handleChange}
                          onFocus={(e) => (e.target.type = "number")}
                          onBlur={(e) =>
                            (e.target.type = formData.balance
                              ? "number"
                              : "text")
                          }
                          className="h-8 border border-custom-border/50 rounded-lg px-1.5 w-full"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-4 text-sm">
                <div className="flex gap-2 items-center">
                  <h1 className="text-sm">Password</h1>
                  <div className="h-8 border border-custom-border/30 rounded-lg px-1.5 flex items-center">
                    <input
                      type="password"
                      placeholder="************"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="outline-none flex-1 "
                    />
                    <button onClick={() => ClipboardCopy(formData.password)}>
                      <Copy style={{ width: "14px" }} />
                    </button>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <h1 className="flex-1 text-sm">Address</h1>
                  <textarea
                    type="address"
                    placeholder="Enter your address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="border border-custom-border/30 rounded-lg p-1 w-auto "
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <h1 className="flex-1 text-sm">Country</h1>
                  <select
                  disabled
                    className="border p-1 rounded-lg border-custom-border/30 relative outline-none cursor-not-allowed opacity-50"
                  >
                    {countryList?.map((country, id) => (
                      <option key={id} value="">
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full mt-12">
              <button
                onClick={handleSubmit}
                disabled={!isFormValid}
                style={{
                  cursor: isFormValid ? "pointer" : "not-allowed",
                  opacity: isFormValid ? 1 : 0.5,
                }}
                className="w-full bg-bg-color hover:bg-bg-color/80 h-10 rounded-xl"
              >
                Add Sub Retailer
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default AddSubRetailerModal;
