import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";

const DeleteModal = ({ message }) => {
  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="fund-modal-container">
          <div>
            <div className="loading-gif">
              <img src="/images/success.gif" alt="gif" loading="lazy" />
            </div>
            <div style={{ textAlign: "center", color: "red" }}>
              <p>{message}</p>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DeleteModal;
