import React, { memo, useState } from "react";
import FundTransferModal from "../Modals/TransferFunds/FundTransferModal";

const SearchUser = ({ searchedUser }) => {
  const [transferModal, setTransferModal] = useState("");
  
  return (
    <>
      {transferModal === "on" && (
        <FundTransferModal
          searchedUser={searchedUser}
          setTransferModal={setTransferModal}
        />
      )}
      <div className="">
        <div className=" flex flex-col sm:flex-row justify-between gap-4">
          <div className="flex gap-4">
            <div>
              <img
                src={searchedUser?.profileImage?.url}
                alt="user"
                className="w-20 h-20 rounded-full"
              />
            </div>
            <div className="user-details">
              <span>Name : {searchedUser.name}</span>
              <span>Email ID : {searchedUser.email}</span>
              <span>Referral Code : {searchedUser.referralCode}</span>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={() => setTransferModal("on")}
              className="w-full sm:w-44 h-10 rounded-xl bg-bg-color hover:bg-bg-color/80"
            >
              Transfer
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SearchUser);
