import React, { useCallback, useEffect, useState } from "react";
import ReceiptSubmitModal from "../Modals/ReceiptUpload/ReceiptSubmitModal";
import SubmitSucceedModal from "../Modals/ReceiptUpload/SubmitSucceedModal";
import { formatDate } from "../../utils/formatTime";
import { useAppContext } from "../../context/AppContext";
import Pagination from "../Pagination";
import { getPendingRecharges } from "../../services/dashboardServices";
import { getUser } from "../../services/auth";
import { Check, File } from "lucide-react";
import VerifyTxnModal from "../Modals/CryptoRecharges/VerifyTxnModal";

const ReceiptPending = () => {
  const [singleReceipt, setSingleReceipt] = useState({});
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userID, setUserID] = useState(null);
  const [symbol, setSymbol] = useState("");

  const { modal, setModal, receiptRefresh, setReceiptRefresh, country } =
    useAppContext();

  const status = "PENDING";
  const pageLimit = 10;

  // Fetch Pending Receipts
  const handleGetPendingReceipt = useCallback(async () => {
    try {
      const user = await getUser();
      const data = await getPendingRecharges(
        user,
        status,
        currentPage,
        pageLimit
      );
      if (data.status) {
        setReceiptRefresh(false);
        setSelectedReceipts(data.data.records);
        setTotalPages(data.data.lastPage);
      }
    } catch (error) {
      console.log(error);
    }
  }, [status, currentPage, setReceiptRefresh]);

  useEffect(() => {
    handleGetPendingReceipt();
  }, [receiptRefresh, currentPage]);

  const handleUploadReceipt = (receipt) => {
    if (receipt) {
      setSingleReceipt(receipt);
      setModal("submit");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCryptoVerify = (id, symbol) => {
    setUserID(id);
    setSymbol(symbol);
    setModal("crypto_verify");
  };

  // console.log(selectedReceipts);

  return (
    <>
      {modal === "submit" && (
        <ReceiptSubmitModal setModal={setModal} singleReceipt={singleReceipt} />
      )}
      {modal === "uploaded" && <SubmitSucceedModal setModal={setModal} />}

      {modal === "crypto_verify" && (
        <VerifyTxnModal setModal={setModal} userID={userID} symbol={symbol} />
      )}
      <div className="">
        <div className="w-full h-full min-h-[438px] p-4 border border-custom-border rounded-xl shadow-md">
          <div className="w-full flex flex-col sm:flex-row justify-between items-center pb-4">
            <h1 className="font-semibold ">Add Funds Receipt Upload Pending</h1>
            <h1 className="font-semibold ">
              Showing {currentPage} of {totalPages}
            </h1>
          </div>
          {selectedReceipts?.length !== 0 ? (
            <div className="max-h-[340px] h-full overflow-x-auto overflow-y-scroll rounded-xl border border-custom-border scrollbar-hide">
              <table className="rounded-xl min-w-full">
                <thead className="border-b border-custom-border sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-bg-color font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-bg-color font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-bg-color font-semibold uppercase tracking-wider whitespace-nowrap border-r border-custom-border"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 text-left text-xs bg-bg-color font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedReceipts?.map((receipt, id) => (
                    <tr
                      key={id}
                      className={` ${
                        id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                      } 
                    ${
                      id !== selectedReceipts?.length - 1
                        ? "border-b border-custom-border"
                        : ""
                    }
                    `}
                    >
                      <td className="p-2 whitespace-nowrap text-sm">
                        <span className="flex justify-start items-center">
                          <img
                            src={country?.currencySymbol?.url || "/images/loading-gif.gif"}
                            alt={country?.currencySymbol?.url ? "symbol" : "loading"}
                            width={16}
                            height={16}
                            title={
                                country?.currencyCode ? country?.currencyCode : "Loading"
                            }
                            style={{ marginRight: "8px" }}
                          />
                          {receipt.price}
                        </span>
                      </td>
                      <td className="p-2 whitespace-nowrap text-sm">
                        {formatDate(receipt.createdAt)}
                      </td>
                      <td className="p-2 whitespace-nowrap text-sm">
                        {receipt.paymentMethod}
                      </td>
                      <td className="p-2 whitespace-nowrap text-sm">
                        {receipt.status === "PENDING" ? (
                          <>
                            {receipt.paymentMethod === "CRYPTO" ? (
                              <button
                                onClick={() =>
                                  handleCryptoVerify(
                                    receipt._id,
                                    receipt.cryptoPlatformSymbol
                                  )
                                }
                                className="add-trans-btn"
                              >
                                Pending
                              </button>
                            ) : (
                              <button
                                onClick={() => handleUploadReceipt(receipt)}
                                className="add-trans-btn"
                              >
                                Pending
                              </button>
                            )}
                          </>
                        ) : (
                          <span title="Verified">
                            <Check style={{ color: "green" }} />
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full h-full">
              <div className="min-h-96 w-full h-full flex flex-col justify-center items-center">
                <span className="bg-yellow-200 p-2 rounded-lg">
                  <File className="w-5 h-5 text-orange-600"/>
                </span>
                <h1 className="text-center font-semibold text-custom-font-color text-sm mt-1">
                  No pending transactions found
                </h1>
              </div>
            </div>
          )}

          <div className="flex justify-center">
            {totalPages > 1 && (
              <div className="mt-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptPending;
