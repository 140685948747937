import React, { memo } from "react";
import ModalLayout from "../../Layouts/ModalLayout";

const SubmitSucceedModal = ({ setModal }) => {
  return (
    <>
      <ModalLayout maxWidth="max-w-96">
        <div className="fund-modal-container">
          <div>
            <div className="loading-gif">
              <img src="/images/success.gif" alt="gif" loading="lazy" />
            </div>
            <div style={{ textAlign: "center", color: "rgba(1, 166, 57, 1)" }}>
              <p>Receipt Submitted</p>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(SubmitSucceedModal);
