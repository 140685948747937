import React, { useCallback, useEffect, useState } from "react";
import AddFundModal from "../Modals/Recharge/AddFundModal";
import AppModal from "../Modals/Recharge/AppModal";
import SucceedModal from "../Modals/Recharge/SucceedModal";
import {
  getAvailableRecharge,
  getPaymentLinks,
} from "../../services/dashboardServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/AppContext";
import SearchUserModal from "../Modals/TransferFunds/SearchUserModal";
import { getUser } from "../../services/auth";
import SubmitRechargeModal from "../Modals/ReceiptUpload/SubmitRechargeModal";
import CryptoTxnModal from "../Modals/CryptoRecharges/CryptoTxnModal";
import QRModal from "../Modals/CryptoRecharges/QRModal";
import VerifyTxnModal from "../Modals/CryptoRecharges/VerifyTxnModal";
import PendingTxn from "../Modals/TransferFunds/PendingTxn";
import LinksDropdown from "../LinksDropdown";
import { useNavigate } from "react-router-dom";
import { Bolt, Forward } from "lucide-react";

// const currencies = [
//   { logo: "/images/ETH.png", name: "ETHEREUM", symbol: "ETH" },
//   { logo: "/images/BTC.png", name: "BITCOIN", symbol: "BTC" },
//   { logo: "/images/USDT.png", name: "Tether USDT", symbol: "USDT" },
//   { logo: "/images/MATIC.png", name: "Polygon", symbol: "MATIC" },
// ];

const FundsComponent = ({ funds }) => {
  const { setReceiptRefresh, modal, setModal, country, userProfile } =
    useAppContext();

  // console.log("country", country );

  const [rechargeLinks, setRechargeLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [rechargeDetails, setRechargeDetails] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState(
    country?.currencyCode
  );
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [amount, setAmount] = useState(null);
  const [convertAmount, setConvertAmount] = useState(null);
  const [network, setNetwork] = useState([]);
  const [cryptoRecDetails, setCryptoRecDetails] = useState({});
  const [availRecharge, setAvailRecharge] = useState({});

  const navigate = useNavigate();

  // console.log("userProfile", userProfile?.role);

  const goToMangeSubPage = () => {
    navigate("/manage-sub-retailer");
  };

  useEffect(() => {
    if (country?.currencyCode) {
      setSelectedCurrency(country.currencyCode);
    }
  }, [country]);

  const handleSelect = (currency) => {
    setSelectedCurrency(currency);
  };

  const handleGetRechargeLinks = useCallback(async () => {
    try {
      const user = await getUser();
      const data = await getPaymentLinks(user);
      if (data.status) {
        setRechargeLinks(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setRechargeLinks]);

  useEffect(() => {
    handleGetRechargeLinks();
  }, []);

  const handleSelectChange = useCallback(
    (selectedOption) => {
      const selected = rechargeLinks.find(
        (item) =>
          item.link === selectedOption.link &&
          item.amount.toString() === selectedOption.amount.toString()
      );

      setSelectedLink(selected);
    },
    [rechargeLinks]
  );

  const handleRechargeClick = useCallback(() => {
    if (selectedLink) {
      setModal("open");
    } else {
      toast.error("Please select an amount.");
    }
  }, [selectedLink, setModal]);

  const resetForm = () => {
    setAmount(null);
    setConvertAmount(null);
    setSelectedNetwork(null);
    setCryptoRecDetails({});
    setModal("crypto-recharge");
  };

  useEffect(() => {
    const handleAvailRecharge = async () => {
      try {
        const user = await getUser();
        const data = await getAvailableRecharge(user);

        if (data.status) {
          setAvailRecharge(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleAvailRecharge();
  }, []);

  return (
    <>
      {modal === "verify" && (
        <VerifyTxnModal
          cryptoRecDetails={cryptoRecDetails}
          selectedNetwork={selectedNetwork}
          selectedCurrency={selectedCurrency}
          setModal={setModal}
        />
      )}
      {modal === "qr-modal" && (
        <QRModal
          selectedCurrency={selectedCurrency}
          setModal={setModal}
          convertAmount={convertAmount}
          cryptoRecDetails={cryptoRecDetails}
        />
      )}
      {modal === "crypto-recharge" && (
        <CryptoTxnModal
          data={{
            amount,
            setAmount,
            selectedCurrency,
            convertAmount,
            setConvertAmount,
            network,
            setNetwork,
            selectedNetwork,
            setSelectedNetwork,
            setCryptoRecDetails,
          }}
        />
      )}
      {modal === "open" && (
        <AddFundModal
          setModal={setModal}
          selectedLink={selectedLink}
          setReceiptRefresh={setReceiptRefresh}
          setRechargeDetails={setRechargeDetails}
        />
      )}
      {modal === "payapp" && (
        <AppModal
          setModal={setModal}
          selectedLink={selectedLink}
          setReceiptRefresh={setReceiptRefresh}
        />
      )}
      {modal === "succeed" && (
        <SucceedModal setModal={setModal} selectedLink={selectedLink} />
      )}
      {modal === "pending" && <PendingTxn />}
      {modal === "fund-transfer" && <SearchUserModal setModal={setModal} />}
      {modal === "submit-2" && (
        <SubmitRechargeModal
          setModal={setModal}
          rechargeDetails={rechargeDetails}
        />
      )}
      <div className="w-full h-full space-y-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full h-32 bg-bg-color flex flex-col justify-center items-center border border-custom-border rounded-xl">
            <span className="flex justify-center items-center">
              <img
                src={country?.currencySymbol?.url || "/images/loading-gif.gif"}
                  alt={country?.currencySymbol?.url ? "symbol" : "loading"}
                  width={20}
                  height={20}
                  title={
                    country?.currencyCode ? country?.currencyCode : "Loading"
                  }
                style={{
                  marginRight: "8px",
                  borderRadius: country?.currencySymbol ? "0" : "100px",
                }}
              />
              <p className="font-semibold text-xl">
                {funds?.totalAddedFund?.toFixed(2) ?? "00.00"}
              </p>
            </span>
            <p className="mt-1">Total Added Funds</p>
          </div>
          <div className="w-full h-32 bg-bg-color flex flex-col justify-center items-center border border-custom-border rounded-xl">
            <span className="flex justify-center items-center">
              <img
                src={country?.currencySymbol?.url || "/images/loading-gif.gif"}
                  alt={country?.currencySymbol?.url ? "symbol" : "loading"}
                  width={20}
                  height={20}
                  title={
                    country?.currencyCode ? country?.currencyCode : "Loading"
                  }
                style={{
                  marginRight: "8px",
                  borderRadius: country?.currencySymbol ? "0" : "100px",
                }}
              />
              <p className="font-semibold text-xl">
                {funds?.totalTransferredFund?.toFixed(2) ?? "00.00"}
              </p>
            </span>
            <p className="mt-1">Total Transfer Funds</p>
          </div>
        </div>
        <div className={`flex flex-col sm:flex-row gap-4 w-full`}>
          <div
            className={` border p-4 rounded-xl border-custom-border h-64 shadow-md w-full`}
          >
            <div
              className={`w-full border border-custom-border bg-bg-color/50 p-4  rounded-xl flex flex-col justify-center items-center ${
                userProfile?.role === "RETAILER" ? "h-28" : "h-full"
              }`}
            >
              <span className="flex justify-center items-center ">
                <img
                  src={country?.currencySymbol?.url || "/images/loading-gif.gif"}
                  alt={country?.currencySymbol?.url ? "symbol" : "loading"}
                  width={20}
                  height={20}
                  title={
                    country?.currencyCode ? country?.currencyCode : "Loading"
                  }
                  style={{
                    marginRight: "8px",
                    borderRadius: country?.currencySymbol ? "0" : "100px",
                  }}
                />
                <p className="font-semibold text-xl">
                  {funds?.nativeBalance?.toFixed(2) ?? "00.00"}
                </p>
              </span>
              <p className="mt-1">Balance</p>
            </div>
            {userProfile?.role === "RETAILER" ? (
              <div>
                <div className="select-curr">
                  <select onChange={(e) => handleSelect(e.target.value)}>
                    {availRecharge?.bank !== false && (
                      <option value={country?.currencyCode}>
                        {country?.currencyCode}
                      </option>
                    )}
                    {availRecharge?.crypto !== false && (
                      <option value="USDT">USDT</option>
                    )}
                  </select>
                </div>
                {availRecharge.bank !== false &&
                selectedCurrency === country?.currencyCode ? (
                  <div className="flex gap-2 mt-2">
                    <LinksDropdown
                      options={rechargeLinks}
                      onChange={handleSelectChange}
                    />
                    <button
                      onClick={handleRechargeClick}
                      className="text-sm bg-custom-border hover:bg-custom-border/80 px-4 rounded-lg font-semibold"
                    >
                      Recharge
                    </button>
                  </div>
                ) : (
                  <div className="mt-2">
                    <button
                      onClick={resetForm}
                      className="w-full h-10 text-sm bg-custom-border hover:bg-custom-border/80 px-4 rounded-lg font-semibold"
                    >
                      Recharge via USDT
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="w-full flex flex-col gap-4">
            <div>
              {/* <div className="network-img">
                <img
                  src="/images/Consultant.png"
                  alt="network"
                  loading="lazy"
                  style={{ width: "28px" }}
                />
              </div> */}
              {userProfile?.role === "RETAILER" ? (
                <button
                  onClick={goToMangeSubPage}
                  className="p-2 bg-custom-border hover:bg-custom-border/80 w-full rounded-xl flex justify-center items-center gap-2"
                >
                  <Bolt className="w-5 h-5" />
                  Manage Sub-Retailer
                </button>
              ) : null}
            </div>
            {/* {userProfile.role
              ? userProfile.role !== "SUB-RETAILER" && ( */}
            <div className="w-full">
              {/* <div className="network-img">
                <img
                  src="/images/Connectivity.png"
                  alt="network"
                  loading="lazy"
                  style={{ width: "28px" }}
                />
              </div> */}

              <button
                onClick={() => setModal("fund-transfer")}
                className="p-2 bg-custom-border hover:bg-custom-border/80 w-full rounded-xl flex justify-center items-center gap-2"
              >
                <Forward className="w-5 h-5" />
                Transfer Funds
              </button>
            </div>
            {/* )
              : "Loading..."} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FundsComponent;
