import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { NotebookText } from "lucide-react";
import React, { useEffect, useState } from "react";
import { transferFundGraph } from "../../services/dashboardServices";
import { useAppContext } from "../../context/AppContext";
import TxnHistoryModal from "../Modals/TxnHistoryModal";
import { getUser } from "../../services/auth";

const TransFundChart = () => {
  const fullYear = new Date().getFullYear();

  const [graphData, setGraphData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(fullYear);
  const { fundRefresh, modal, setModal } = useAppContext();

  const handleGraphData = async () => {
    try {
      const user = await getUser();
      const data = await transferFundGraph(user, selectedYear);
      if (data.status) {
        setGraphData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGraphData();
  }, [fundRefresh, selectedYear]);

  const options = {
    chart: {
      type: "spline",
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
      height: "340px",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          const value = this.value;
          if (value >= 1e12) {
            return (value / 1e12).toFixed(1) + "T"; // Trillion
          } else if (value >= 1e9) {
            return (value / 1e9).toFixed(1) + "B"; // Billion
          } else if (value >= 1e6) {
            return (value / 1e6).toFixed(1) + "M"; // Million
          } else if (value >= 1e3) {
            return (value / 1e3).toFixed(1) + "K"; // Thousand
          } else {
            return value;
          }
        },
      },
    },
    series: [
      {
        name: "Total Fund Transfer Per Month",
        data: graphData,
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(197, 59, 59, 1)"],
            [1, "black"],
          ],
        },
      },
    ],
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };

  return (
    <>
      {modal === "txn-history" && <TxnHistoryModal setModal={setModal} />}
      <div className="border p-4 border-custom-border rounded-xl shadow-md h-full">
        <div className="w-full flex justify-between items-center">
          <h1 className="font-semibold">Funds Transfer History</h1>
          <span className="date-picker">
            <button
              title="Fund Transfer History"
              onClick={() => setModal("txn-history")}
              className="bg-bg-color/50 hover:bg-bg-color h-full p-1 rounded-lg border border-custom-border"
            >
              <NotebookText className="w-5 h-5" />
            </button>
            <select onChange={(e) => setSelectedYear(e.target.value)}>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
            </select>
          </span>
        </div>
        <div className="mt-8">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </>
  );
};

export default TransFundChart;
