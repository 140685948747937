import React, { useEffect, useState } from "react";
import "./auth.css";
import AuthLayout from "../../components/Layouts/AuthLayout";
import { Eye, EyeOff, LockKeyhole, Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/userServices";
import { toast } from "react-toastify";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassowrd, setShowPassowrd] = useState(false);

  const navigate = useNavigate();

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      if (!email && !password) {
        toast.error("Please fill all the required fields");
        return;
      }
      const data = await login(email, password);
      if (data.status) {
        // console.log("data", data);
        window.location.href = `/otp-verification?email=${email}`;
        navigate("/otp-verification");
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const user = localStorage.getItem("user");

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <>
      <AuthLayout>
        <div className="w-full p-6">
          <div className="">
            <img src="/images/large-logo.png" />
            <p className="text-sm italic font-semibold">
              NS Exchange Retailer Admin...
            </p>
          </div>
          <div className="mt-12">
            <form onSubmit={handleSubmitForm}>
              <div className="flex items-center gap-3 border border-custom-border/50 h-11 px-2 rounded-lg">
                <Mail className="w-5 h-5" />
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="outline-none w-full bg-transparent"
                  id="email"
                  placeholder="Email ID"
                />
              </div>
              <div>
                <p className="text-sm mt-1">
                  OTP will be sent to your Registered Email Address
                </p>
              </div>
              <div className="flex items-center gap-3 border border-custom-border/50 h-11 px-2 rounded-lg mt-4">
                <LockKeyhole className="w-5 h-5" />
                <input
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={`${showPassowrd ? "text" : "password"}`}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                />
                <button
                  style={{ backgroundColor: "transparent" }}
                  type="button"
                  onClick={() => setShowPassowrd(!showPassowrd)}
                >
                  {showPassowrd ? <Eye /> : <EyeOff />}
                </button>
              </div>
              <div className="mt-12">
                <button
                  type="submit"
                  className="bg-custom-border w-full h-11 rounded-xl sm:text-lg font-semibold"
                >
                  Request OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default LoginScreen;
