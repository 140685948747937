import { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext();

export const AppContextWrapper = ({ children }) => {
  const [modal, setModal] = useState("");
  const [receiptRefresh, setReceiptRefresh] = useState(false);
  const [fundRefresh, setFundRefresh] = useState(false);
  const [retailRefresh, setRetailRefresh] = useState(false);
  const [country, setCountry] = useState({});
  const [userProfile, setUserProfile] = useState({});

  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const sharedState = {
    user,
    modal,
    setModal,
    receiptRefresh,
    setReceiptRefresh,
    fundRefresh,
    setFundRefresh,
    retailRefresh,
    setRetailRefresh,
    country,
    setCountry,
    userProfile,
    setUserProfile,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
