import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../context/AppContext";

const ModalLayout = ({ children, maxWidth = "max-w-xl" }) => {
  const { modal, setModal } = useAppContext();
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModal("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal]);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/20 z-50 flex justify-center items-center p-2 overflow-y-auto">
  <div
    ref={modalRef}
    className={`relative w-full ${maxWidth} max-h-[97vh] rounded-xl overflow-y-auto bg-white`}
  >
    {children}
  </div>
</div>

  );
};

export default ModalLayout;
