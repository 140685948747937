import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

const LeaveModal = ({ setBack }) => {
  const navigate = useNavigate();
  return (
    <ModalLayout>
      <div className="leave-modal-container">
        <div onClick={() => setBack(false)} className="leave">
          <X />
        </div>
        <div className="leave-content">
          <p>Are you sure you want to leave this page?</p>
        </div>
        <div className="leave-btn-div">
          <button onClick={() => navigate("/")} className="leave-btn">
            Yes
          </button>
          <button onClick={() => setBack(false)} className="cancel-btn">
            No
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default LeaveModal;
