import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { Copy, X } from "lucide-react";
import QRCode from "react-qr-code";
import ClipboardCopy from "../../../utils/copyToClipboard";
import { useAppContext } from "../../../context/AppContext";

const QRModal = ({
  setModal,
  selectedCurrency,
  convertAmount,
  cryptoRecDetails,
}) => {
  // console.log(cryptoRecDetails);

  const { country } = useAppContext();
  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="crypto-recharge-container">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">QR Code</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className="qr_container">
            <div className="iawd_paiw">
              <div className="flex justify-center items-center gap-1 font-medium">
                {/* <span>
                  {cryptoRecDetails?.cryptoPlatformLogo ? (
                    <img
                      src={cryptoRecDetails?.cryptoPlatformLogo}
                      alt="logo"
                      loading="lazy"
                      className="w-5 h-5"
                    />
                  ) : null}
                </span> */}
                <span>{convertAmount}</span>
                <span>
                  {/* {cryptoRecDetails?.cryptoPlatformSymbol} */}
                  USDT
                </span>
              </div>
              <p style={{ fontSize: "14px" }}>QR Code</p>
              <div style={{ width: "60%" }}>
                <div className="awd3_wq2">
                  <QRCode value={cryptoRecDetails?.qr} className="qr-code" />
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    marginTop: "2px",
                  }}
                >
                  Convenience Fees{" "}
                  <span style={{ color: "red" }}>
                    {cryptoRecDetails?.convenienceAmount}
                  </span>{" "}
                  {cryptoRecDetails?.cryptoSymbol}
                </p>
              </div>
              <div className="qrws_peww">
                <img
                  src="/images/metamask.png"
                  alt="logo"
                  width={25}
                  height={25}
                  loading="lazy"
                />
                <img
                  src="/images/WBNB.svg"
                  alt="logo"
                  width={25}
                  height={25}
                  loading="lazy"
                />
                <img
                  src="/images/BTC.png"
                  alt="logo"
                  width={25}
                  height={25}
                  loading="lazy"
                />
              </div>
              <div style={{ width: "80%" }}>
                <div className="input_aswye">
                  <input value={cryptoRecDetails?.toAddress} readOnly />
                  <button
                    onClick={() => ClipboardCopy(cryptoRecDetails?.toAddress)}
                    className="qr_yute"
                  >
                    <Copy style={{ width: "16px", height: "16px" }} />
                  </button>
                </div>
              </div>
            </div>

            <div className="">
              <p className="text-xs text-center py-4 max-w-72 w-full">
                Copy the "Transaction Hash" to verify your payment and click
                "Verify" once done.
              </p>

              <button
                onClick={() => setModal("verify")}
                className="w-full bg-bg-color py-2 rounded-xl font-semibold tracking-wider"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default QRModal;
