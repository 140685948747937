import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";

const RedirectAppModal = ({setModal}) => {
  return (
    <>
      <ModalLayout>
        <div className="fund-modal-container">
          <div>
            <div className="loading-gif">
              <img
                src="https://s3-alpha-sig.figma.com/img/8f2e/c485/4bcf84f2ae23d0b925b23f767f0d6ed2?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cfyP~Gvz9bnhhOJBgIuLRnJ9pBkHqWv4da3xPf7ewKxMLgjmVclWD6VVN3OtptG4b0qd9qO5HlU7lq-Kq5qgEoyryqniHN4wFb3W4~~i4O~NGNLzBGPaolxJhKcEipws2bn081aoAB-qUz3XN0pd9LYwJeUoq2-bFo3xNYOAcigH6-JQ1fUJ7LHBRHvJuSz4mmpud~Bpkuns4~kpedMM5T04ORRGfPiUmepqsCiuNsAJgh6s1pWzirceIv6VxCQ1KWzultRoIZRXbNctpS1-OiJgOY2x~qIzrttaDMiBQyhKi3sh4ttBMNDg-EAtm-p1NE52gkfBykQs3p~mH23zmw__"
                alt="gif"
                loading="lazy"
              />
            </div>
            <div onClick={() => setModal("payapp")} className="load-notice">
              <p>Please wait</p>
              <p>Redirecting to payment application</p>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default RedirectAppModal;
