import React from "react";

const SkullLoader = () => {
  return (
    <>
      <span className="loader"></span>
    </>
  );
};

export default SkullLoader;
