import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminRoute = () => {
  const user = localStorage.getItem("user");

  return <>{user !== null ? <Outlet /> : <Navigate to="/" replace />}</>;
};

export default AdminRoute;
