import React, { useState, useEffect, useRef } from "react";
import { LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const Header = () => {
  const [logoutOpen, setLogoutOpen] = useState(false);
  const { modal, setModal } = useAppContext();

  const logoutRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setLogoutOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleLogout = () => {
    setLogoutOpen(!logoutOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <>
      <div className="w-full bg-gradient-to-b from-[#bd9a42e4] to-[#ffffff] flex justify-between items-center px-4 py-1">
        <div className="">
          <img
            loading="lazy"
            src="/images/large-logo.png"
            alt="Logo"
            className="w-56"
          />
        </div>

        <div onClick={toggleLogout} ref={logoutRef} className="cursor-pointer">
          <img
            width={65}
            height={65}
            loading="lazy"
            src="/images/user.png"
            alt="Logo"
          />
        </div>

        {logoutOpen && (
          <div
            className="absolute right-2 top-[4.5rem] w-30 p-1 mt-2 border border-gray-300 rounded-md bg-white z-50"
            ref={logoutRef}
          >
            <button
              type="button"
              className="w-full flex justify-start items-center gap-2 p-2 rounded-md text-red-600 font-medium text-[15px] hover:bg-red-200"
              onClick={handleLogout}
            >
              <LogOut style={{ width: "15px" }} /> Logout
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
