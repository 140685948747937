import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import LoginScreen from "./screens/Auth/LoginScreen";
import DashboardScreen from "./screens/Admin/DashboardScreen";
import { AppContextWrapper } from "./context/AppContext";
import OtpScreen from "./screens/Auth/OtpScreen";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AdminRoute from "./components/AdminRoute";
import ManageSubRetailserScreen from "./screens/ManageRetailer/ManageSubRetailserScreen";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "./components/ProtectedRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" index={true} element={<LoginScreen />} />
      <Route path="" element={<AdminRoute />}>
        <Route path="/dashboard" element={<DashboardScreen />} />
        <Route path="" element={<ProtectedRoute />}>
          <Route
            path="/manage-sub-retailer"
            element={<ManageSubRetailserScreen />}
          />
        </Route>
      </Route>
      <Route path="/otp-verification" element={<OtpScreen />} />
    </Route>
  )
);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppContextWrapper>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
      <ToastContainer />
    </AppContextWrapper>
  </React.StrictMode>
);

reportWebVitals();
