import { httpAxios } from "../helper/httpHelper";

export async function login(email, password) {
  try {
    const { data } = await httpAxios.post(`/api/v1/retailer/auth/otp/login`, {
      email,
      password,
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
}
export async function verifyOTP(email, otp) {
  try {
    const { data } = await httpAxios.post(`/api/v1/retailer/auth/login`, {
      email,
      otp,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getUserProfile(user) {
  try {
    const { data } = await httpAxios.get(`/api/v1/retailer/auth/profile/get`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getCountryList(user) {
  try {
    const { data } = await httpAxios.get(`/api/v1/retailer/auth/country/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}


