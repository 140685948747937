export const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    hourCycle: "h12",
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", options)
    .format(date)
    .replace(/,/g, "")
    .toUpperCase();
};

export const formatDateMonthYear = (isoDateString) => {
  const date = new Date(isoDateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());

  return `${day}/${month}/${year}`;
};
