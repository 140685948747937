import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { getUser } from "../../../services/auth";
import { deleteMember } from "../../../services/subRetailerServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";

const DeleteWarrningModal = ({ selectedMember }) => {
  const { setModal, setRetailRefresh } = useAppContext();

  const handleDeleteMember = async () => {
    try {
      setModal("pending");
      const user = await getUser();
      const res = await deleteMember(user, selectedMember.id);
      if (res.status) {
        setRetailRefresh(true);
        setModal("delete");
        toast.success(res.message);
      }
    } catch (error) {
      setModal("delete-warrning");
      console.error(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="confirmation-card">
          <div className="card-header">
            <h2>Confirmation</h2>
            <X className="close-icon" onClick={() => setModal("")} />
          </div>
          <p className="warr-p">
            Are you sure you want to{" "}
            <span style={{ color: "red" }}>
              <b>Delete</b>
            </span>{" "}
            this Sub Retailer?
          </p>
          <div className="w-full h-full flex gap-2">
            <button
              onClick={handleDeleteMember}
              className="w-full h-10 rounded-xl bg-bg-color hover:bg-bg-color/85"
            >
              Delete
            </button>
            <button
              className="w-full h-10 rounded-xl border border-bg-color hover:opacity-85"
              onClick={() => setModal("")}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DeleteWarrningModal;
