import { httpAxios } from "../helper/httpHelper";

export async function createMember(user, formData) {
  try {
    const params = {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      password: formData.password,
      balance: Number(formData.balance),
    };

    const { data } = await httpAxios.post(
      `/api/v1/retailer/subretailer/create`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function subRetailerMembersList(user, currPage, limit, search) {
  try {
    const searchQuery = search ? `&search=${search}` : "";
    
    const { data } = await httpAxios.get(
      `/api/v1/retailer/subretailer/list?page=${currPage}&limit=${limit}${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );
    
    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function addAmount(user, userId, amount) {
  try {
    const params = {
      userId: userId,
      amount: amount,
    };

    const { data } = await httpAxios.post(
      `/api/v1/retailer/subretailer/balance/add`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deductAmount(user, userId, amount) {
  try {
    const params = {
      userId: userId,
      amount: amount,
    };

    const { data } = await httpAxios.post(
      `/api/v1/retailer/subretailer/balance/deduct`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteMember(user, userId) {
  try {
    const { data } = await httpAxios.delete(
      `/api/v1/retailer/subretailer/delete?id=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function memberTxnsList(user, userId, currPage, limit) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/subretailer/balance/transaction/list?userId=${userId}&page=${currPage}&limit=${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function editSubRetailer(user, userId, formData) {
  try {
    const params = {
      id: userId,
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      password: formData.password,
    };
    
    const { data } = await httpAxios.put(
      `/api/v1/retailer/subretailer/update`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: user,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}
