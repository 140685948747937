import { ArrowLeft } from "lucide-react";
import React, { useState } from "react";
import AuthLayout from "../../components/Layouts/AuthLayout";
import OTPInput from "react-otp-input";
import LeaveModal from "../../components/Modals/LeaveModal";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOTP } from "../../services/userServices";
import { toast } from "react-toastify";

const OtpScreen = () => {
  const [otp, setOtp] = useState("");
  const [back, setBack] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const handleVerify = async (e) => {
    e.preventDefault();

    if (otp === "") {
      toast.error("Please enter your otp");
      return;
    }

    try {
      const data = await verifyOTP(email, otp);
      if (data.status) {
        toast.success(data.message);
        navigate("/dashboard");
        localStorage.setItem("user", JSON.stringify(data.data));
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
      if (error.message === "Request failed with status code 403") {
        toast.error("Wrong OTP. Please try again");
      }
    }
  };

  return (
    <>
      {back && <LeaveModal setBack={setBack} />}
      <AuthLayout>
        <div className="w-full p-6">
          <div className="">
            <img src="/images/large-logo.png" />
            <p className="text-sm italic font-semibold">
            NS Exchange Retailer Admin...
            </p>
          </div>
          <div className="">
            <form onSubmit={handleVerify}>
              <div className="mt-12">
                <h1 className="mb-1 font-semibold">Enter OTP</h1>
                <OTPInput
                  skipDefaultStyles
                  inputStyle="outline-none"
                  containerStyle="w-full flex justify-between items-center gap-2 sm:gap-4 w-full"
                  inputType="text"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      id={`otp-input-${index}`}
                      name={`otp-input-${index}`}
                      className="w-full bg-custom-bg h-14 outline-none text-center border border-custom-border rounded-lg text-xl"
                    />
                  )}
                />
              </div>
              <div className="flex justify-between mt-1">
                <p className="text-sm">
                  OTP sent to your Registered Email Address
                </p>
                {/* <button className="text-sm font-bold">Resend</button> */}
              </div>
              <div className="w-full h-11 flex mt-12 gap-4">
                <button
                  onClick={() => setBack(true)}
                  className="w-full bg-black hover:bg-black/80 text-white rounded-xl font-semibold"
                  type="button"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="bg-custom-border hover:bg-custom-border/80 w-full rounded-xl text-lg font-semibold"
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default OtpScreen;
