import React from "react";
import "./table.css";
import { formatDateMonthYear } from "../../utils/formatTime";
import { useAppContext } from "../../context/AppContext";

const SubRetailerTxnTable = ({ transactions, currentPage, limit }) => {
  // console.log("transactions", transactions);
  const { country } = useAppContext();

  // console.log(country);

  return (
    <>
      <div className="mt-2">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto overflow-y-hidden">
            <div className="align-middle inline-block min-w-full">
              <div className="overflow-hidden sm:rounded-lg border border-custom-border">
                <table className="min-w-full  ">
                  <thead className="bg-sub-card border-b  border-custom-border bg-bg-color ">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Serial No.
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Transaction ID
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Method
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        <span className="flex">
                            Amount (
                            <img
                              src={
                                country?.currencySymbol?.url ||
                                "/images/loading-gif.gif"
                              }
                              alt={
                                country?.currencySymbol?.url
                                  ? "symbol"
                                  : "loading"
                              }
                              width={16}
                              height={16}
                              title={
                                country?.currencyCode
                                  ? country?.currencyCode
                                  : "Loading"
                              }
                            />
                            )
                          </span>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((txn, id) => {
                      const isDeduct =
                        txn.transactionType === "SUB-RETAILER-NATIVE-DEDUCT";
                      const amount = isDeduct ? -txn.price : txn.price;

                      return (
                        <tr
                          key={id}
                          className={
                            id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                          }
                        >
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {id + 1 + (currentPage - 1) * limit}.
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {txn?.referenceNumber ?? "-"}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {isDeduct ? "Deduct" : "Add"}
                          </td>
                          <td
                            className={`font-semibold px-4 py-2 whitespace-nowrap text-sm ${
                              amount > 0 ? "text-emerald-500" : "text-red-500"
                            }`}
                          >
                            {amount > 0 ? "+" : ""}
                            {amount}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {formatDateMonthYear(txn.createdAt)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubRetailerTxnTable;
