import React, { memo } from "react";
import { formatDate } from "../utils/formatTime";
import { useAppContext } from "../context/AppContext";
import { File } from "lucide-react";

const TxnTable = ({ data, currentPage, itemsPerPage }) => {
  const { country } = useAppContext();
  // console.log(data);

  return (
    <>
      {data.length > 0 ? (
        <div className="mt-2">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto overflow-y-hidden">
              <div className="align-middle inline-block min-w-full">
                <div className="overflow-hidden sm:rounded-lg border border-custom-border">
                  <table className="min-w-full  ">
                    <thead className="bg-sub-card border-b  border-custom-border bg-bg-color ">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Serial No.
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Referral Code
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Method
                        </th>
                        {/* <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Reference Number
                        </th> */}
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          <span className="flex">
                            Amount (
                            <img
                              src={
                                country?.currencySymbol?.url ||
                                "/images/loading-gif.gif"
                              }
                              alt={
                                country?.currencySymbol?.url
                                  ? "symbol"
                                  : "loading"
                              }
                              width={16}
                              height={16}
                              title={
                                country?.currencyCode
                                  ? country?.currencyCode
                                  : "Loading"
                              }
                            />
                            )
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Transaction ID
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Date & Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((txns, i) => (
                        <tr
                          key={i}
                          className={
                            i % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center gap-1.5">
                            {i + 1 + (currentPage - 1) * itemsPerPage}.
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {txns.user.referralCode}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {txns.paymentMethod}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {txns.referenceNumber}
                          </td> */}
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {txns.price}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {txns.referenceNumber ?? "-"}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm flex items-center gap-1.5 `}
                          >
                            {formatDate(txns?.createdAt)}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm italic font-medium ${
                              txns.status === "APPROVED"
                                ? "text-emerald-500"
                                : txns.status === "PENDING"
                                ? "text-orange-500"
                                : "text-red-600"
                            }`}
                          >
                            {txns.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-64 flex flex-col gap-1 justify-center items-center">
          <span className="bg-bg-color/50 p-2 rounded-lg">
            <File className="w-5 h-5 text-yellow-800" />
          </span>
          <p>Not found.</p>
        </div>
      )}
    </>
  );
};

export default memo(TxnTable);
