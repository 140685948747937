import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";

const PendingTxn = () => {
  return (
    <>
      <ModalLayout maxWidth="max-w-96">
        <div className="fund-modal-container">
          <div>
            <div className="loading-gif">
              <img
                src="/images/loading.gif"
                alt="gif"
                loading="lazy"
              />
            </div>
            <div className="load-notice">
              <p>Please wait</p>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default PendingTxn;
