import { httpAxios } from "../helper/httpHelper";

export async function getFunds(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/dashboard/fund/get`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function addFundGraph(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/dashboard/fund/add/graph/get?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function transferFundGraph(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/dashboard/fund/transfer/graph/get?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getPaymentLinks(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/payment-links/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function recharge(user, userID) {
  try {
    const { data } = await httpAxios.post(
      `/api/v1/retailer/wallet/add-fund/request/create`,
      {
        beneficiaryId: userID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getPendingRecharges(user, status, currPage, pageLimit) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/add-fund/request/list?status=${status}&page=${currPage}&limit=${pageLimit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchUserById(user, searchQuery) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/user/get?search=${searchQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function transferAmount(user, userId, amount) {
  try {
    const { data } = await httpAxios.post(
      `/api/v1/retailer/wallet/transfer/create`,
      {
        userId,
        amount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchTransactions(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/transfer/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchFundHistory(
  user,
  page,
  limit,
  status,
  date,
  search
) {
  try {
    const params = {
      page: page,
      limit: limit,
    };

    if (status) params.status = status;
    if (date) params.date = date;
    if (search) params.search = search;

    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/add-fund/request/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportFundsCSV(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/add-fund/request/export`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function exportTxnCSV(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/transfer/export`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

// Need to be monitoring ⌚
export async function updateRecharge(user, id, referenceNumber, receiptId) {
  try {
    const params = {
      id: id,
      referenceNumber: referenceNumber,
      receiptId: receiptId,
    };

    const { data } = await httpAxios.put(
      `/api/v1/retailer/wallet/add-fund/request/update`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    // Log the error response for debugging
    console.error("Error Response:", error.response?.data || error.message);
    return error.response?.data || { message: "Update failed" };
  }
}

export async function userTransferHistory(user, userId, page, limit) {
  try {
    const params = {
      userId: userId,
      page: page,
      limit: limit,
    };

    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/transfer/list`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getNetworkLists(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/recharge/crypto/platform/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getLiveUSDTPrice(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/recharge/crypto/price/get`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createRechargeReq(
  user,
  id,
  amount,
  cryptoWalletTypeNetworkId
) {
  try {
    const params = {
      id: id,
      amount: amount,
      cryptoWalletTypeNetworkId: cryptoWalletTypeNetworkId,
    };

    const { data } = await httpAxios.post(
      `/api/v1/retailer/wallet/recharge/crypto`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
}

// Need to be monitoring ⌚
export async function verifyTxnHash(user, id, transactionId) {
  try {
    const params = {
      id: id,
      transactionId: transactionId,
    };

    // console.log("params",params);

    const { data } = await httpAxios.post(
      `/api/v1/retailer/wallet/recharge/crypto/hash/verify`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function submitTxnHash(user, id, txnHash) {
  try {
    const params = {
      id: id,
      transactionId: txnHash,
    };

    const { data } = await httpAxios.post(
      `/api/v1/retailer/wallet/recharge/crypto/hash`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getAvailableRecharge(user) {
  try {
    const { data } = await httpAxios.get(
      `/api/v1/retailer/wallet/recharge/config`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function uploadMediaImg(user, file) {
  try {
    const formData = new FormData();
    formData.append("medias", file);

    const { data } = await httpAxios.post(`/api/v1/media/media/add`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}
