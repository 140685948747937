import React, { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { getUser } from "../../../services/auth";
import { deductAmount } from "../../../services/subRetailerServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";

const DeductAmountModal = ({ selectedMember }) => {
  const { setModal, setRetailRefresh } = useAppContext();
  const [amount, setAmount] = useState("");

  const handleSubmit = async () => {
    try {
      setModal("pending");
      const user = await getUser();
      const data = await deductAmount(user, selectedMember.id, Number(amount));

      if (data.status) {
        setModal("success");
        setRetailRefresh(true);
        toast.success(data.message);
      } else {
        setModal("deduct-amount");
        toast.error(data.message);
      }
    } catch (error) {
      setModal("deduct-amount");
      console.error(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-96">
        <div className="w-full h-full">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Deduct Amount</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="p-4 w-full h-full flex flex-col justify-center items-center gap-8">
            <div className="mt-8 w-full">
              <p>Amount</p>
              <input
                placeholder="Enter amount"
                type="number"
                title="amount"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onFocus={(e) => (e.target.type = "number")}
                onBlur={(e) => (e.target.type = amount ? "number" : "text")}
                className="w-full border border-bg-color outline-none h-10 p-1 rounded-xl mt-2"
              />
            </div>
            <div className="mt-8">
              <button
                onClick={handleSubmit}
                type="button"
                className="bg-bg-color hover:bg-bg-color/80 p-2 w-56 rounded-xl"
              >
                Deduct Amount
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default DeductAmountModal;
