import React from "react";

const AuthLayout = ({ children }) => {
  return (
    <>
      <div className="min-h-screen h-full w-full flex">
        <div className="w-full lg:w-4/12 xl:w-3/12 min-h-screen h-full flex justify-center items-center">{children}</div>
        <div className=" w-full lg:w-8/12 xl:w-9/12 hidden md:block ">
          <img
            src="/images/login-pic.jpeg"
            alt="Background Image"
            loading="lazy"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
