import React, { useState, useEffect } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X } from "lucide-react";
import { getUser } from "../../../services/auth";
import {
  submitTxnHash,
  verifyTxnHash,
} from "../../../services/dashboardServices";
import { toast } from "react-toastify";

const VerifyTxnModal = ({
  cryptoRecDetails,
  setModal,
  selectedNetwork,
  userID,
  symbol,
}) => {
  const [txnHash, setTxnHash] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (txnHash) {
        handleVerifyTxn();
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [txnHash]);

  const handleVerifyTxn = async () => {
    try {
      const usdtId = JSON.parse(localStorage.getItem("usdtPrice"));

      const user = await getUser();
      const data = await verifyTxnHash(user, usdtId?.usdtPrice?.id, txnHash);
      if (data.status) {
        setVerificationStatus("Valid");
        setIsVerified(true);
      } else {
        setVerificationStatus(`Invalid (${data.message}...)`);
        setIsVerified(false);
      }
    } catch (error) {
      console.error(error);
      setVerificationStatus("Error");
      setIsVerified(false);
    }
  };

  const handleSubmitHash = async () => {
    try {
      setModal("pending");
      const usdtId = JSON.parse(localStorage.getItem("usdtPrice"));
      const user = await getUser();
      const data = await submitTxnHash(user, usdtId?.usdtPrice?.id, txnHash);

      if (data.status) {
        setModal("succeed");
      } else {
        setModal("verify");
        toast.error(data.message);
      }
    } catch (error) {
      setModal("verify");
      console.error(error);
    }
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="crypto-recharge-container">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Verify Transaction</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className="p-4">
            <div>
              <h1 className="">Transaction Hash</h1>
              <div className="mt-2">
                <input
                  onChange={(e) => {
                    setTxnHash(e.target.value);
                    setVerificationStatus("");
                    setIsVerified(false);
                  }}
                  value={txnHash}
                  title="Transaction Hash"
                  name="hash"
                  type="text"
                  placeholder="Enter"
                  className="w-full border border-bg-color/50 p-1 h-10 rounded-lg outline-none"
                />
                <p
                  className="check_txn"
                  style={isVerified ? { color: "green" } : { color: "red" }}
                >
                  {verificationStatus}
                </p>
              </div>
            </div>

            <div className="w-full mt-8 flex justify-center">
              <button
                onClick={handleSubmitHash}
                disabled={!isVerified}
                style={{
                  opacity: isVerified ? 1 : 0.5,
                  cursor: isVerified ? "pointer" : "not-allowed",
                }}
                className="w-56 bg-bg-color py-1.5 rounded-xl"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default VerifyTxnModal;
