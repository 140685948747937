import React from "react";
import Header from "../Headers/Header";
import "./layout.css";

const MainLayout = ({ children }) => {
  return (
    <>
      <div className="w-full min-h-screen h-full">
        <Header />
        <div className="w-full min-h-screen h-full p-4 ">{children}</div>
      </div>
    </>
  );
};

export default MainLayout;
