import React, { memo, useEffect } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { recharge } from "../../../services/dashboardServices";
import { toast } from "react-toastify";
import { getUser } from "../../../services/auth";

const AppModal = ({ setModal, selectedLink, setReceiptRefresh }) => {
  // Recharge function
  const handleRecharge = async () => {
    try {
      if (selectedLink._id) {
        const user = await getUser();
        const data = await recharge(user, selectedLink?._id);
        // console.log("Recharge", data);
        if (data.status) {
          setReceiptRefresh(true);
          setModal("succeed");
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("User not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalLayout>
        <div className="fund-modal-container">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Payment Application</p>
            {/* <button onClick={handleRecharge} className="add-fund-btn">
              Pay NOW
            </button> */}
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(AppModal);
