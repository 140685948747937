import { X } from "lucide-react";
import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";

const AddWarrningModal = ({ setModal }) => {
  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="confirmation-card">
          <div className="card-header">
            <h2>Confirmation</h2>
            <X className="close-icon" onClick={() => setModal("")} />
          </div>
          <p className="warr-p">Are you sure you want to <span className="font-semibold">Add</span> Amount?</p>
          <div className="w-full h-full flex gap-2">
            <button
              className="w-full h-10 rounded-xl bg-bg-color hover:bg-bg-color/85"
              onClick={() => setModal("add-amount")}
            >
              Confirm
            </button>
            <button className="w-full h-10 rounded-xl border border-bg-color hover:opacity-85" onClick={() => setModal("")}>
              Cancel
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default AddWarrningModal;
