import React, { useState, useEffect, useCallback } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { CloudDownload, Search, X } from "lucide-react";
import TxnTable from "../TxnTable";
import Pagination from "../Pagination"; // Adjust the import path as necessary
import {
  exportTxnCSV,
  fetchTransactions,
} from "../../services/dashboardServices";
import { toast } from "react-toastify";
import { getUser } from "../../services/auth";

const TxnHistoryModal = ({ setModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState("APPROVED");
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const limit = 8;

  const handleExportTxns = async () => {
    try {
      const user = await getUser();
      const csvData = await exportTxnCSV(user);

      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", "Transfer History.csv");

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      toast.error("Failed to export CSV.");
    }
  };

  const loadTransactions = useCallback(
    async (page, status, date, search) => {
      try {
        const user = await getUser();
        const result = await fetchTransactions(
          user,
          page,
          limit,
          status,
          date,
          search
        );
        setData(result.data.records);
        setTotalPages(result.data.lastPage);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [limit, setData, setTotalPages]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    loadTransactions(currentPage, status, date, debouncedSearch);
  }, [currentPage, status, date, debouncedSearch, loadTransactions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <ModalLayout maxWidth="max-w-5xl">
        <div className="bg-white">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="text-2xl">Transfer History</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col sm:flex-row gap-2">
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-lg w-full">
                <span className="">
                  <Search style={{ width: "20px" }} />
                </span>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  title="search"
                  name="Search"
                  placeholder="Search..."
                  className="outline-none "
                />
              </div>
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-lg w-full">
                <input
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  title="search"
                  name="Date"
                  placeholder="Search"
                  className="outline-none w-full"
                />
              </div>
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-lg w-full">
                <select
                  onChange={(e) => setStatus(e.target.value)}
                  className="outline-none w-full"
                >
                  <option value="" disabled hidden>
                    Status
                  </option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="PENDING">PENDING</option>
                </select>
              </div>
              <div className="flex gap-1 border border-custom-border/30 p-1 rounded-lg w-full">
                <button
                  onClick={handleExportTxns}
                  className=" h-full flex items-center justify-center gap-1 bg-bg-color w-full rounded-lg px-2"
                >
                  <CloudDownload className="w-4 h-4" /> <span>|</span>CSV
                </button>
              </div>
            </div>
            <div className="py-4">
              <TxnTable data={data} currentPage={currentPage} itemsPerPage={limit}/>
            </div>
            <div className="flex justify-center items-center">
              {totalPages > 1 ? (
                <div className="pagination-container">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default TxnHistoryModal;
