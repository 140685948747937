import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (pageOrDirection) => {
    if (typeof pageOrDirection === "number") {
      const page = pageOrDirection;
      if (page >= 1 && page <= totalPages) {
        onPageChange(page);
      }
    } else {
      const direction = pageOrDirection;
      let nextPage = currentPage;
      if (direction === "first") {
        nextPage = 1;
      } else if (direction === "prev") {
        nextPage = Math.max(currentPage - 1, 1);
      } else if (direction === "next") {
        nextPage = Math.min(currentPage + 1, totalPages);
      } else if (direction === "last") {
        nextPage = totalPages;
      }
      onPageChange(nextPage);
    }
  };

  // Helper function to generate page numbers
  const generatePageNumbers = () => {
    const delta = 2; // Number of pages to display around the current page
    const range = [];

    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(totalPages - 1, currentPage + delta);
      i++
    ) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift("...");
    }
    if (currentPage + delta < totalPages - 1) {
      range.push("...");
    }

    range.unshift(1);
    if (totalPages > 1) {
      range.push(totalPages);
    }

    return range;
  };

  return (
    <div className="flex items-center gap-1 rounded-lg w-fit font-crimson bg-custom-bg text-xs sm:text-sm">
      <button
        onClick={() => handleClick("first")}
        disabled={currentPage === 1}
        className={`px-3 py-2 rounded-md bg-bg-color ${
          currentPage === 1
            ? "cursor-not-allowed opacity-30"
            : "hover:bg-bg-color/80 hover:text-white"
        }`}
      >
        <ChevronsLeft className="w-2 h-2 sm:w-4 sm:h-3 " />
      </button>
      <button
        onClick={() => handleClick("prev")}
        disabled={currentPage === 1}
        className={`px-3 py-2 bg-bg-color rounded-md ${
          currentPage === 1
            ? "cursor-not-allowed opacity-30 "
            : "hover:bg-bg-color/80 hover:text-white"
        }`}
      >
        <ChevronLeft className="w-2 h-2 sm:w-4 sm:h-3 " />
      </button>
      {generatePageNumbers().map((page, index) => (
        <button
          key={index}
          className={`px-3 py-1 bg-bg-color hover:bg-[#664900]/70 hover:text-white rounded-md ${
            currentPage === page ? "bg-[#664900] text-white" : ""
          }`}
          onClick={() => handleClick(page)}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handleClick("next")}
        disabled={currentPage === totalPages}
        className={`px-3 py-2 bg-bg-color rounded-md ${
          currentPage === totalPages
            ? "cursor-not-allowed opacity-30"
            : "hover:bg-bg-color/80 hover:text-white "
        }`}
      >
        <ChevronRight className="w-2 h-2 sm:w-4 sm:h-3 " />
      </button>
      <button
        onClick={() => handleClick("last")}
        disabled={currentPage === totalPages}
        className={`px-3 py-2 bg-bg-color rounded-md ${
          currentPage === totalPages
            ? "cursor-not-allowed opacity-30"
            : "hover:bg-bg-color/80 hover:text-white "
        }`}
      >
        <ChevronsRight className="w-2 h-2 sm:w-4 sm:h-3 " />
      </button>
    </div>
  );
};

export default Pagination;
